<template>
    <v-card flat class="mt-2">
        <v-form class="multi-col-validation">
            <v-card-title>Register Lanjutan Universitas</v-card-title>
            <v-card-text class="">
                <v-row>
                    <v-col cols="6" md="3">
                        <v-text-field v-model="user.username" label="Username :" dense outlined readonly></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-text-field v-model="user.email" label="Email :" dense outlined readonly></v-text-field>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-autocomplete v-model="dokter.institusi_pendidikan" :items="institusi_pendidikans" outlined dense
                            label="Institusi Pendidikan :" item-text="nama" item-value="id"></v-autocomplete>
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-text-field v-model="dokter.no_hp" label="No Handphone :" dense outlined></v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="dokter.alamat" label="Alamat :" dense outlined></v-text-field>
                    </v-col>
                </v-row>
                <!-- <h3 class="ml-2 mt-2">Upload Dokumen :</h3>
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    No
                                </th>
                                <th class="text-left">
                                    Nama
                                </th>
                                <th class="text-left">
                                    File
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Surat Pengantar dari institusi pendidikan</td>
                                <td>
                                    <upload-dokumen-registrasi namaDokumen="surat_pengantar"></upload-dokumen-registrasi>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Ijazah</td>
                                <td><upload-dokumen-registrasi namaDokumen="ijazah"></upload-dokumen-registrasi></td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Sertifikat Vaksin Booster 1/rapid antigen 1x24 jam sebelum praktek klinik</td>
                                <td><upload-dokumen-registrasi namaDokumen="sertifikat_vaksin"></upload-dokumen-registrasi>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Pas Foto background merah (menggunakan seragam institusi)</td>
                                <td><upload-dokumen-registrasi namaDokumen="pas_foto"></upload-dokumen-registrasi></td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table> -->
            </v-card-text>

            <v-card-text>
                <v-btn color="primary" class="me-3 mt-3" @click="simpan">
                    Simpan
                </v-btn>
            </v-card-text>
        </v-form>
    </v-card>
</template>

<script>
import axios from 'axios'
import UploadDokumenRegistrasi from '@/components/upload/UploadDokumenRegistrasi.vue'

const apiRoot = process.env.VUE_APP_APIROOT
export default {
    components: {
        UploadDokumenRegistrasi,
    },
    data: () => ({
        dokter: {
            username: '',
            email: '',
            // nama: '',
            // nim: '',
            institusi_pendidikan: '',
            // prodi: '',
            // jenis_kelamin: '',
            alamat: '',
            no_hp: '',
            // dokumen: [
            //     'surat_pengantar',
            //     'ijazah',
            //     'sertifikat_vaksin',
            //     'pas_foto',
            // ]
        },
        institusi_pendidikans: [],
        prodis: [],
    }),
    computed: {
        headers() {
            return {
                Authorization: this.$store.state.token,
            }
        },
        user() {
            return this.$store.getters.user
        },
    },
    created() {
        this.getInstitusiPendidikan()
        this.getProdi()
    },
    methods: {
        getInstitusiPendidikan() {
            const headers = this.headers
            axios
                .get(`${apiRoot}/api/RegisterLanjutan/getInstitusiPendidikans`, { headers })
                .then(response => {
                    if (response.data.code === 200) {
                        this.institusi_pendidikans = response.data.datas
                    } else {
                        alert(response.data.message)
                    }
                })
                .catch(error => {
                    alert(error)
                })
        },
        getProdi() {
            // this.loading = true
            const headers = this.headers
            axios
                .get(`${apiRoot}/api/RegisterLanjutan/getProdis`, { headers })
                .then(response => {
                    if (response.data.code === 200) {
                        this.prodis = response.data.datas
                    } else {
                        alert(response.data.message)
                    }
                })
                .catch(error => {
                    alert(error)
                })
        },
        simpan() {
            // this.loading = true
            const headers = this.headers
            const fmData = new FormData()
            fmData.append('username', this.dokter.username)
            fmData.append('email', this.dokter.email)
            fmData.append('institusi_pendidikan', this.dokter.institusi_pendidikan)
            fmData.append('alamat', this.dokter.alamat)
            fmData.append('no_hp', this.dokter.no_hp)
            // fmData.append('dokumen', this.dokter.dokumen)
            fmData.append('role_temp', this.user.role_temp)
            axios
                .post(`${apiRoot}/api/RegisterLanjutan/addRegistrasiUniversitas`, fmData, { headers })
                .then(response => {
                    if (response.data.code === 200) {
                        this.$store.dispatch('logout', response.data.message)
                    } else {
                        alert(response.data.message)
                    }
                })
                .catch(error => {
                    alert(error)
                })
        },
    },
}
</script>