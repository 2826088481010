<template>
  <div>
    <div v-if="user.role == 'REGISTERED'">
      <div v-if="user.role_temp == 'PESERTA DIDIK'">
        <register-peserta-dokter v-if="user.profesi == 'Dokter'"></register-peserta-dokter>
        <register-peserta-pPDS v-if="user.profesi == 'PPDS'"></register-peserta-pPDS>
        <register-peserta-keperawatanDll
          v-if="user.profesi == 'Keperawatan,Kebidanan, dan Tenaga Kesehatan Lain'"
        ></register-peserta-keperawatanDll>
      </div>
      <div v-else-if="user.role_temp == 'PEMBIMBING KLINIK'">
        <register-pembimbing></register-pembimbing>
      </div>
      <div v-else-if="user.role_temp == 'UNIVERSITAS'">
        <register-universitas></register-universitas>
      </div>
      <div v-else>
        <v-card flat>
          <v-card-text class=""> Data anda sedang diverifikasi oleh admin. silahkan menunggu </v-card-text>
        </v-card>
      </div>
    </div>
    <div v-else>
      <v-card flat>
        <v-card-title>Selamat datang {{ user.nama }}</v-card-title>
        <!-- <v-card-text class="">
          <h2></h2>
        </v-card-text> -->
      </v-card>
    </div>
    <v-card flat class="mt-2">
      <v-card-title>Layanan Informasi:</v-card-title>
      <v-card-text>
        <ul>
          <li>
            Email Timkordik : <a href="mailto: timkordik.rsudsoeselo@gmail.com">timkordik.rsudsoeselo@gmail.com</a>
          </li>
          <li>
            Email Komkordik : <a href="mailto: komkordik.rsudsoeselo@gmail.com">komkordik.rsudsoeselo@gmail.com</a>
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <!-- <v-card flat class="mt-2">
      <v-card-title>Panduan Aplikasi &nbsp;<a style="font-size: 16px;" target="_blank" href="https://rsudsoeselo.tegalkab.go.id/komkordik/layanan-informasi-panduan">Lihat</a></v-card-title>
    </v-card> -->
  </div>
</template>

<script>
import RegisterPesertaDokter from './RegisterPeserta/RegisterPesertaDokter.vue'
import RegisterPesertaPPDS from './RegisterPeserta/RegisterPesertaPPDS.vue'
import RegisterPesertaKeperawatanDll from './RegisterPeserta/RegisterPesertaKeperawatanDll.vue'
import RegisterPembimbing from './RegisterPembimbing/RegisterPembimbing.vue'
import RegisterUniversitas from './RegisterUniversitas.vue'

export default {
  components: {
    RegisterPesertaDokter,
    RegisterPesertaPPDS,
    RegisterPesertaKeperawatanDll,
    RegisterPembimbing,
    RegisterUniversitas,
  },
  data: () => ({}),
  computed: {
    user() {
      return this.$store.getters.user
    },
  },
  methods: {},
}
</script>
