<template>
    <div>
        <v-card flat class="mt-2">
            <v-form class="multi-col-validation">
                <v-card-title>Register Lanjutan Pembimbing Klinik</v-card-title>
                <v-card-text class="">
                    <v-row>
                        <v-col cols="6" md="3">
                            <v-text-field v-model="user.username" label="Username :" dense outlined readonly></v-text-field>
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-text-field v-model="user.email" label="Email :" dense outlined readonly></v-text-field>
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-text-field v-model="userTambahan.nama" label="Nama :" dense outlined></v-text-field>
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-text-field v-model="userTambahan.nip" label="NIP/NRB :" dense outlined></v-text-field>
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-text-field v-model="userTambahan.masa_kerja" label="Masa Kerja (Tahun) :" dense type="number"
                                outlined></v-text-field>
                        </v-col>
                        <v-col cols="6" md="6">
                            <v-text-field v-model="userTambahan.alamat" label="Alamat :" dense outlined></v-text-field>
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-text-field v-model="userTambahan.no_hp" label="No Handphone :" dense outlined></v-text-field>
                        </v-col>
                        <v-col cols="6" md="3">
                            <v-autocomplete v-model="userTambahan.dokdiknis" :items="dokdiknis" outlined dense
                                label="Dokdiknis/Pembimbing :" item-text="nama" item-value="id"></v-autocomplete>
                        </v-col>
                    </v-row>
                    <h3 class="ml-2 mt-2">Upload Dokumen :</h3>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        No
                                    </th>
                                    <th class="text-left">
                                        Nama
                                    </th>
                                    <th class="text-left">
                                        File
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in userTambahan.dokumen" v-bind:key="item.id">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.nama }}</td>
                                    <td><upload-dokumen-registrasi :namaDokumen="item.id"></upload-dokumen-registrasi></td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>

                <v-card-text>
                    <v-btn color="primary" class="me-3 mt-3" @click="simpan">
                        Simpan
                    </v-btn>
                </v-card-text>
            </v-form>
        </v-card>
    </div>
</template>

<script>
import axios from 'axios'
// import { axiosPostAuth } from '@/utils/axios'
import UploadDokumenRegistrasi from '@/components/upload/UploadDokumenRegistrasi.vue'

const apiRoot = process.env.VUE_APP_APIROOT
export default {
    components: {
        UploadDokumenRegistrasi,
    },
    data: () => ({
        userTambahan: {
            username: '',
            email: '',
            nama: '',
            nip: '',
            masa_kerja: '',
            alamat: '',
            no_hp: '',
            dokdiknis: '',
            dokumen: [
                {
                    id: 'ijazah',
                    nama: 'Ijazah'
                },
                {
                    id: 'str',
                    nama: 'STR'
                },
                {
                    id: 'sip',
                    nama: 'SIP'
                },
                {
                    id: 'ktp',
                    nama: 'KTP'
                },
                {
                    id: 'npwp',
                    nama: 'NPWP'
                }
            ]
        },
        dokdiknis: [],
    }),
    computed: {
        headers() {
            return {
                Authorization: this.$store.state.token,
            }
        },
        user() {
            return this.$store.getters.user
        },
    },
    created() {
        this.getInstitusiPendidikan()
        this.getPilihanDokdiknis()
    },
    methods: {
        getInstitusiPendidikan() {
            const headers = this.headers
            axios
                .get(`${apiRoot}/api/RegisterLanjutan/getInstitusiPendidikans`, { headers })
                .then(response => {
                    if (response.data.code === 200) {
                        this.institusi_pendidikans = response.data.datas
                    } else {
                        alert(response.data.message)
                    }
                })
                .catch(error => {
                    alert(error)
                })
        },
        getPilihanDokdiknis() {
            // this.loading = true
            const headers = this.headers
            axios
                .get(`${apiRoot}/api/RegisterLanjutan/getPilihanDokdiknis`, { headers })
                .then(response => {
                    if (response.data.code === 200) {
                        this.dokdiknis = response.data.datas
                    } else {
                        alert(response.data.message)
                    }
                })
                .catch(error => {
                    alert(error)
                })
        },
        simpan() {
            const headers = this.headers
            const fmData = new FormData()
            fmData.append('username', this.userTambahan.username)
            fmData.append('email', this.userTambahan.email)
            fmData.append('nama', this.userTambahan.nama)
            fmData.append('nip', this.userTambahan.nip)
            fmData.append('masa_kerja', this.userTambahan.masa_kerja)
            fmData.append('alamat', this.userTambahan.alamat)
            fmData.append('no_hp', this.userTambahan.no_hp)
            fmData.append('dokdiknis', this.userTambahan.dokdiknis)
            const dokumen = [];
            this.userTambahan.dokumen.forEach(element => {
                dokumen.push(element.id)
            });
            fmData.append('dokumen', dokumen)
            fmData.append('role_temp', this.user.role_temp)
            axios
                .post(`${apiRoot}/api/RegisterLanjutan/addRegistrasiPembimbing`, fmData, { headers })
                .then(response => {
                    if (response.data.code === 200) {
                        this.$store.dispatch('logout', response.data.message)
                    } else {
                        alert(response.data.message)
                    }
                })
                .catch(error => {
                    alert(error)
                })
        },
    },
}
</script>